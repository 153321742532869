import React from 'react';
import { Link } from 'gatsby';
import SimplePageSection from '../../../containers/SaasModern/SimplePage';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import PropTypes from 'prop-types';
import Me from '../../../../../common/src/assets/image/dan.png';
import ContactForm from 'common/src/components/ContactForm';
import Heading from 'reusecore/src/elements/Heading';

const textStyle = { marginBottom: 0 };

const About = ({ row, colOne, colTwo }) => {
  const content = (
    <div>
      <Heading as="h1" content="About VueSoft LLC" />
      <Box className="row" {...row}>
        <Box {...colOne}>
          <p style={textStyle}>
            Hello, I'm Dan Chartrand, founder of VueSoft LLC, an independent
            software company established in 2008 and located near St. Louis,
            Missouri, USA.{' '}
          </p>
          <p style={textStyle}>
            VueSoft's focus is on providing the very best Windows-based software
            for home and business use. We place an emphasis on balancing
            attractive and simple user interfaces with powerful features and
            flexibility.{' '}
          </p>
          <p style={textStyle}>
            VueSoft also created{' '}
            <a
              href="https://www.vueminder.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              VueMinder
            </a>
            , an award-winning calendar program for Windows. PrintableCal
            handles customization and printing of your schedule, but if you're
            also looking for software that enables you to input your schedule,
            share your schedule with other people, and get reminders - VueMinder
            does all that and more.
          </p>
          <p style={textStyle}>
            VueSoft also developed{' '}
            <a
              href="https://csv-to-ics.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              CSV-to-ICS Converter
            </a>
            , a free tool for converting CSV files into iCalendar (ICS) files
            which can be imported into almost any calendar app, including Google
            Calendar, Outlook, VueMinder, etc.
          </p>
        </Box>
        <Box {...colTwo}>
          <Image alt="Dan Chartrand" src={Me} />
        </Box>
      </Box>
      <Heading as="h2" content="Contact Us" />
      <p style={textStyle}>
        Customer satisfaction is vitally important to us. If you have any
        questions, find any problems, or think of any improvements that could be
        made to our products, please let us know. We think you'll be pleasantly
        surprised at how responsive we'll be to ensure your complete
        satisfaction!
      </p>
      <div style={{ marginTop: 32 }}>
        <ContactForm />
      </div>
    </div>
  );

  return (
    <SimplePageSection
      title="About VueSoft LLC"
      description="VueSoft LLC was founded in 2008. We're located near St. Louis, Missouri. Our goal is to create the best software for Windows. We value our customers and provide excellent service."
      keywords="printable calendar, calendar template, Excel calendar, Word calendar, calendar add-in, holiday calendar, 2020 calendar, best calendar, VueMinder"
      content={content}
    />
  );
};

About.propTypes = {
  row: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
};

About.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  colOne: {
    width: [1, '65%', '65%', '80%'],
    flexBox: true,
    flexWrap: 'wrap',
    mt: 0,
    mb: ['30px', 0],
    pl: ['15px', 0],
    pr: '24px',
  },
  colTwo: {
    width: [1, '35%', '35%', '20%'],
  },
};

export default About;
